.navbarContainer{
    background-color: white;
    height: 90px;
    width: 80.5vw;
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
    margin: 0 auto;
    box-shadow: 0px 2px 7px rgb(0 0 0 / 7%);
    align-items: center;
    /* border-bottom: 0.2px solid black; */
    top: 0;
    position: sticky;
    z-index: 999;
}

.nexgenLogo img{
    height: 70px;
}

.menuItems{
   padding: 15px;
   background-color: #00C9FF;
    border-radius: 5px;
}

.menuItems {
    color: white;
}

a{
    color: white ;
}