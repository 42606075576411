.mainLayoutContainer{
    display: flex;
    height: 100vh;
}

.sidebarContainer{
    width: 15%;
    background-color: rgb(253, 247, 239);
    overflow-y: scroll;
}

.mainContentContainer{
    width: 85%;
    padding: 3% 0 3% 3%;
    overflow-y: scroll;
}