.heroContainer{
    width: 100%;
    height: 370px;
    max-height: 900em;
    overflow: hidden;
    background-image: url(../../Assets/Hero.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-color: #cccccc;
    position: relative;
    text-align: center;
    /* opacity: 0.7; */
    filter: brightness(70%);
    margin-bottom: 5%;
}

.heroContainer h1 {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for text size */
    color: white !important; /* Adjust color for contrast */
   
}