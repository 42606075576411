.jobDetailsContainer{
    /* display: flex;
    align-items: center;
    justify-content: left; */
    text-align: left;
    width: 70%;
    /* background-color: antiquewhite; */
    padding: 10px 2% 5% 10%;
}

.jobDescPage{
    display: flex;
    /* flex-wrap: wrap; */
}

.job{
    display: flex;
    flex-direction: column;
}
.contactContainer{
    width: 40%;
    margin: 5% 2% 0 0;
}

.jobDescriptions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: justify;
    max-width: 100%;
}

tr{
    padding: 3px;
    background-color: white;
}

button{
    border: none;
    background-color: #00C9FF;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 25px 20px 25px 0;
}

.applyBtn{
    width: 30%;
}

@media only screen and (max-width: 920px) {
    .jobDescPage{
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
    }
  }

  @media only screen and (max-width: 560px) {
    .jobDescPage{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .contactContainer{
        width: 40%;
        margin: 0;
        padding: 0 20%;
        background-color: white;
    }
    .applyBtn{
        width: 100%;
    }
  }