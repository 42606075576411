.jobForm{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    /* background-color: white; */
    /* margin: 5% 0; */
    padding: 0 10%;
}

h1{
    text-align: center;
}

input, textarea {
    padding: 10px 0;
    margin: 10px;
    width: 50%;
}

.column{
    display: flex;
    align-items: center;
    justify-content: left;
}

.btn{
    width: 70%;
    cursor: pointer;
}