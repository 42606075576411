form {
    /* background-color: antiquewhite; */
    /* padding: 10px;
    margin: 10px auto; */

}
.dashboardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow-y: scroll; */
}
.createJobContainer {
    display: flex;
    /* max-width: 80%; */
    /* width: 100%; */
    flex-wrap: wrap;
    text-align: right;

}

.formInputs {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    min-width: 40%; 
}

.inputDiv2{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    min-width: 81%; 
}

label {
    font-size: 14px;
    font-weight: 400;
}

input,
select {
    width: 80% !important;
    padding: 10px 0;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
}