.bannerContainer {
    display: flex;
    padding: 0 5%;
    margin: 5% 0 0 0;
    padding: 5%;
    gap: 10%;
    background-color: white;
    flex-wrap: wrap-reverse;
    /* align-items: center; */
}

.careerImg img {
    height: 400px;
    width: 600px;
}

.bannerText {
    text-align: justify;
    max-width: 50%;
}

@media only screen and (max-width:1440px) {
    .bannerText {
        text-align: left;
        max-width: 40%;
    }
    .careerImg img{
        /* height: 350px; */
        object-fit: cover;
        width: 100%;
    }
}

@media only screen and (max-width:1340px) {
    .bannerContainer{
        justify-content: center;
    }
    .bannerText {
        text-align: left;
        max-width: 80%;
    }
    .careerImg img{
        /* height: 350px; */
        object-fit: contain;
        width: 100% !important;
    }
}

@media only screen and (max-width: 1024px) {
    .bannerText {
        text-align: justify;
        max-width: 100%;
    }

    .careerImg img{
        /* height: 350px; */
        object-fit: cover;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .bannerText {
        text-align: justify;
        max-width: 100%;
    }

    .careerImg img{
        height: 350px;
        width: 100%;
    }
}

@media only screen and (max-width: 580px) {
    .careerImg img{
        height: 350px;
        width: 90vw;
    }
}