a{
    color: black;
    text-decoration: none;
}

.filterContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 55%;
    padding: 20px 25px;
    text-align: left;
    margin: 0 auto;
    background-color: #efefef;
    flex-wrap: wrap;
}
.featuredJobsDesc{
    text-align: justify;
    max-width: 59%;
}

.filter{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}

input{
    border-radius: none;
    border: none;
    width: auto;
    background-color: white;
    min-width: 20em;
    margin: 10px 10px !important;
}

label{
    margin: 0 10px !important;
    font-size: 12px;
}

.jobContainer{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: center;
    background-color: #FFFFFF;
    padding: 5% 10px;
    max-width: 55%;
    margin: 5px auto;
    border-radius: 5px;
    padding: 0 25px;
    flex-wrap: wrap;
}

.jobContainer img{
    height: 60px;
    width: 75px;
}

.jobDetails{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.jobDescription{
    display: flex;
    flex-wrap: wrap;
    /* letter-spacing: 0.05cqh; */
   
}

@media only screen and (max-width: 1024px){
    .jobContainer{
        max-width: 80%;
    }
    .filterContainer{
        max-width: 80%;
    }
    .featuredJobsDesc{
        max-width: 80%;
    }
}

@media only screen and (max-width: 580px){
    .jobContainer{
        align-items: center;
        justify-content: center;
    }
    .jobDescription{
      font-size: small;
      align-items: center;
      justify-content: center;
      
    }
    .dateContainer{
        display: none;
    }
}

@media only screen and (max-width: 480px){
    .jobDescription {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}